<template>
  <!-- 考勤组 -->
  <div style="margin-top: 20px;">
    <div class="box-card" style="text-align:left;">
      <!-- 查询表单 -->
      <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
        ref="queryFormRef">
        <el-form-item label="考勤组：">
          <el-input maxlength="30" v-model="queryForm.attendance_group_name" @keyup.enter.native="onSubmit()"
            @input="(e) => (queryForm.attendance_group_name = validSpace(e))" placeholder="请输入考勤组"></el-input>
        </el-form-item>
        <el-form-item label="考勤规则：">
          <el-input maxlength="30" v-model="queryForm.attendance_rules_name" @keyup.enter.native="onSubmit()"
            @input="(e) => (queryForm.attendance_rules_name = validSpace(e))" placeholder="请输入考勤规则"></el-input>
        </el-form-item>
        <el-form-item>
          <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
        </el-form-item>
      </el-form>
      <le-card title="考勤组">
        <template slot="heard-right">
          <div class="right">
            <lbButton type="err" icon="shanchu1" @click="batchDel" v-if="isShowBtn('fbf60e11b0434b40afc8b30b')">删除
            </lbButton>
            <lbButton icon="xinzeng" @click="openAddPage"
              v-if='this.getUserInfo.user_name != "admin" && isShowBtn("ba7b992441204743a2d4cc0d")'>新增</lbButton>
          </div>
        </template>
        <!-- 主要内容 -->
        <div>
          <!-- 表格 -->
          <!-- 使用插件生产表格 -->
          <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @selection-change="selectionChange"
            @size-change="sizeChange" @current-change="currentChange">
            <template slot-scope="{row}" slot="attendance_personnel">
              <el-link type="primary" target="_blank" v-if="row.attendance_personnel.length > 0"
                  @click="openAttendancePersonnel(row)">{{ row.attendance_personnel.length }}</el-link>
                <span v-else>{{ row.attendance_personnel.length }}</span>
            </template>
               <template slot-scope="{row}" slot="create_time">
                {{ $moment(row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
            <template slot-scope="{row}" slot="menu">
              <lbButton type="warning" icon="bianji" hint="编辑" @click="openEditPage(row)"
                  v-if="isShowBtn('f1441521d5db48eba71703c9')"></lbButton>
            </template>
          </avue-crud> -->
          <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe
            @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" align="center" header-align="center"></el-table-column>
            <el-table-column prop="organize_name" label="组织"></el-table-column>
            <el-table-column prop="attendance_group_name" label="考勤组"></el-table-column>
            <el-table-column label="人数" width="100">
              <template slot-scope="scope">
                <el-link type="primary" target="_blank" v-if="scope.row.attendance_personnel.length > 0"
                  @click="openAttendancePersonnel(scope.row)">{{ scope.row.attendance_personnel.length }}</el-link>
                <span v-else>{{ scope.row.attendance_personnel.length }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="attendance_rules_name" label="考勤规则"></el-table-column>
            <el-table-column prop="regional_rules_name" label="区域规则">
            </el-table-column>
            <el-table-column label="创建时间" width="200">
              <template slot-scope="scope">
                {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
              </template>
            </el-table-column>
            <el-table-column prop="" label="操作" width="120">
              <template slot-scope="scope">
                <lbButton type="warning" icon="bianji" hint="编辑" @click="openEditPage(scope.row)"
                  v-if="isShowBtn('f1441521d5db48eba71703c9')"></lbButton>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
        </div>
      </le-card>
    </div>
    <!-- 新增、编辑考勤组对话框 -->
    <lebo-dialog :title="dialogTitle" :isShow="dialogVisible" width="40%" @close="dialogClose('addFormRef')" footerSlot>
      <div v-if="isLoadingDialog" class="maskLoading">正在加载...</div>
      <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="120px" v-else>
        <el-form-item label="考勤组：" prop="attendance_group_name">
          <el-input v-model="addForm.attendance_group_name" maxlength="30"
            @input="(e) => (addForm.attendance_group_name = validSpace(e))" placeholder="请输入考勤组名称" style="width:80%;">
          </el-input>
        </el-form-item>
        <el-form-item label="组织：" prop="organize_id">
            <el-select popper-class="my-select" filterable v-model="addForm.organize_id" placeholder="请选择组织" clearable
              @change="selectOrganize()" style="width:80%;">
              <el-option v-for="item in organizationSeleteList" :key="item._id" :label="item.organize_name"
                :value="item._id"></el-option>
            </el-select>
          </el-form-item>
        <el-form-item label="考勤规则：" prop="attendance_rules_id">
          <el-select popper-class="my-select" v-model="addForm.attendance_rules_id" placeholder="请选择考勤规则"
            style="width:80%;" @change="attendanceRulesChange">
            <el-option v-for="item in attendanceRulesList" :key="item._id" :label="item.rule_name" :value="item._id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="区域规则：">
          <el-select popper-class="my-select" v-model="addForm.regional_rules_id" placeholder="请选择区域规则" style="width:80%;"
            @change="regionalRulesChange" clearable
            @clear="addForm.regional_rules_id = ''; addForm.regional_rules_name = '';">
            <el-option v-for="item in regionalRulesList" :key="item._id" :label="item.rule_name" :value="item._id">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="考勤人员：" prop="attendance_personnel">
          <el-select popper-class="my-select" collapse-tags filterable v-model="addForm.attendance_personnel" multiple placeholder="请选择考勤人员" style="width:80%;">
            <el-option v-for="item in attendancePersonList" :key="item._id" :label="item.user_name" :value="item._id"></el-option>
          </el-select>
        </el-form-item> -->
        <!-- 新增组织 -->
        <el-form-item label="考勤人员：" prop="attendance_personnel_count">
          <el-select popper-class="my-select" ref="organizeSelect" v-model="addForm.attendance_personnel_count"
            placeholder="请选择考勤人员" :popper-append-to-body="false" style="width:80%;">
            <el-option disabled clearable :value="addForm.attendance_personnel_count" class="setstyle">
              <el-tree @check="handleNodeClick" :data="attendancePersonList" show-checkbox :props="defaultProps"
                ref="attendancePersonTree" node-key="id" :highlight-current="true"
                :default-checked-keys="addForm.selectionIds" default-expand-all>
              </el-tree>
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <lebo-dialog width="30%" title="友情提示" :isShow="isFriendshipToSubmit" @close="isFriendshipToSubmit = false" footerSlot>
        <div style="text-align: left;text-indent: 2em; ">修改成功后，该考勤人员本月内的考勤统计数据将全部清空，统一按修改后的考勤规则进行统计，您确定立即修改吗？</div>
        <div slot="footer" class="dialog-footer">
          <lbButton type="default" fill icon="confirm" @click="fnUpdateAttendanceGroup()">确 定</lbButton>
        </div>
      </lebo-dialog>
      <span slot="footer" class="dialog-footer">
        <lbButton type="default" fill icon="confirm" @click="submitAddForm()" v-preventReClick>保 存</lbButton>
      </span>
    </lebo-dialog>
    <!-- 考勤组人员详情对话框 -->
    <lebo-dialog title="考勤组人员" :isShow="isAttendancePersonnel" width="30%" @close="isAttendancePersonnel = false"
      footerSlot closeOnClickModal>
      <el-table :data="attendancePersonnelData" tooltip-effect="dark" style="width: 100%" border stripe height="460">
        <el-table-column type="index" width="150" align="center" header-align="center" label="序号"></el-table-column>
        <el-table-column prop="user_name" label="姓名"></el-table-column>
        <el-table-column prop="person_type" label="人员类型">
          <template slot-scope="scope">
            <span>{{ getWayName("RegisterPersonType", scope.row.person_type) }} </span>
          </template>
        </el-table-column>
      </el-table>
    </lebo-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import { getAttendanceGroupList, delAttendanceGroup, addAttendanceGroup, updateAttendanceGroup, getAttendanceRulesList, getRegionalRulesList, getAttendanceStatisticsInspectorList, getAttendanceInspectorList, getMembersWithoutConfiguredRules, getOrganizList } from '@/api/administrative'
import { getOrganizationSeleteList } from '@/api/securityApi'

export default {
  data () {
    return {
      queryForm: {
        attendance_group_name: '',
        attendance_rules_name: '',
        PageIndex: 1,
        PageSize: 10
      },
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      tableData: [],
      total: 0,
      multipleSelection: [],
      dialogVisible: false,
      dialogTitle: '',
      addForm: {
        _id: '',
        attendance_group_name: '',
        attendance_rules_id: '',
        attendance_rules_name: '',
        regional_rules_id: '',
        regional_rules_name: '',
        attendance_personnel_count: '', // 已选择XX人；
        attendance_personnel: [], //  巡检人员id+已选择人的全部id
        selected_organize_ids: [], // 选中的组织架构最底层架构id
        organize_id: '', // 组织id
        selectionIds: [] // 选中的所有id值
      },
      row: {},
      addFormRules: {
        attendance_group_name: [
          { required: true, message: '请输入考勤组名称', trigger: 'blur' }
        ],
        attendance_rules_id: [
          { required: true, message: '请选择考勤规则', trigger: 'change' }
        ],
        // regional_rules_id: [
        //   { required: true, message: '请选择区域规则', trigger: 'change' },
        // ],
        attendance_personnel: [
          { type: 'array', required: true, message: '请选择考勤人员', trigger: 'blur' }
        ],
        organize_id: [
          { required: true, message: '请选组织', trigger: 'change' }
        ]

      },
      // 考勤规则下拉列
      attendanceRulesList: [],
      // 区域规则下拉列
      regionalRulesList: [],
      // 考勤人员下拉列
      attendancePersonList: [],
      // 友情提示对话框
      isFriendshipToSubmit: false,
      // 考勤组人员对话框
      isAttendancePersonnel: false,
      // 考勤组人员数据列表
      attendancePersonnelData: [],
      // 考勤组人员数据列表id
      attendancePersonnelDataId: [],
      // 考勤适用人员列表
      attendanceJgData: [],
      // 组织下拉列表
      organizationSeleteList: [],
      organizationPersonnelID: [], // 选中的组织人员id
      inspectionPersonnelID: [], // 选中的巡检人员id
      person_count: 0,
      // 保存修改表单的字符串
      updateFormString: '',
      isLoadingDialog: false, // 弹框正在加载数据
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        selection: true,
        selectionFixed: false, // 解除选择框冻结
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '考勤组',
            prop: 'attendance_group_name'
          }, {
            label: '人数',
            prop: 'attendance_personnel',
            slot: true
          }, {
            label: '考勤规则',
            prop: 'attendance_rules_name'
          }, {
            label: '区域规则',
            prop: 'regional_rules_name'
          }, {
            label: '创建时间',
            prop: 'create_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  created () {
    this.fnGetAttendanceGroupList()
  },
  mounted () {
  },

  computed: {
    ...mapGetters(['getUserInfo', 'isAdmin', 'getOptions', 'getWayName'])
  },
  methods: {
    async fnGetAttendanceGroupList () {
      const res = await getAttendanceGroupList(this.queryForm)
      console.log('res', res)
      if (res && res.Code === 200) {
        if (res.Data && res.Data.DataList.length > 0) {
          this.tableData = res.Data.DataList
          this.total = res.Data.TotalCount
        } else {
          this.tableData = []
          this.total = 0
        }
      } else {
        this.tableData = []
        this.total = 0
      }
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 获取树控件
    // async fnAttendancePersonList (id) {
    //   // console.log('考情下拉', id)
    //   return getAttendanceInspectorList(id)
    // },
    // 适用人员
    handleNodeClick (data, node) {
      this.attendancePersonnelDataId = []
      // 组织id
      this.organizationPersonnelID = []
      // 巡检id
      this.inspectionPersonnelID = []
      console.log('适用人员---', data)
      console.log('node', node)
      this.person_count = 0
      // -----------------
      // var checknode = node.checkedNodes.concat(node.halfCheckedNodes)
      // checknode.forEach(item => {
      //   if (item.persondata.length > 0) {
      //     this.person_count += item.persondata.length
      //     item.persondata.forEach(jtem => {
      //       this.attendancePersonnelDataId.push(jtem)
      //     })
      //   }
      // })
      // -------------------
      // 获取选择的人员，收费员与组织下的人员分开放置
      const checkList = node.checkedNodes
      const inspectionPersonList = []
      const OrganizationPersonList = []
      checkList.forEach(element => {
        if (element.code) {
          OrganizationPersonList.push(element)
        } else {
          inspectionPersonList.push(element)
        }
      })
      console.log('巡检人组', inspectionPersonList)
      console.log('组织人', OrganizationPersonList)
      OrganizationPersonList.forEach(item => {
        if (item.children.length === 0 || !item.children) {
          // 到达最底层组织架构
          this.organizationPersonnelID.push(item.id)
        }
      })
      console.log('架构id', this.organizationPersonnelID)
      // ======
      // inspectionPersonList.forEach(item => {
      //   this.person_count += item.persondata.length
      //   this.inspectionPersonnelID.push(item.id)
      // })
      // ==
      var checknode = node.checkedNodes.concat(node.halfCheckedNodes)
      checknode.forEach(item => {
        if (item.persondata.length > 0) {
          this.person_count += item.persondata.length
          item.persondata.forEach(jtem => {
            this.inspectionPersonnelID.push(jtem)
          })
        }
      })
      console.log('人员id', this.inspectionPersonnelID)
      // ---------------------------
      this.addForm.attendance_personnel_count = '已选择' + this.person_count + '人'
      this.addForm.selected_organize_ids = this.organizationPersonnelID
      this.addForm.attendance_personnel = this.inspectionPersonnelID

      // console.log('适用人员---', this.addForm.attendance_personnel);
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fnGetAttendanceGroupList()
    },
    // 每页条数改变
    handleSizeChange (val) {
      //  // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fnGetAttendanceGroupList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fnGetAttendanceGroupList()
    // },
    // 页码改变
    handleCurrentChange (val) {
      this.queryForm.PageIndex = val
      this.fnGetAttendanceGroupList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
      //  // console.log(`当前页: ${val}`);
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fnGetAttendanceGroupList()
    // },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange (val) {
      // console.log(val);
      this.multipleSelection = val
    },
    // // 选中的数据
    // selectionChange (list) {
    //   this.multipleSelection = list
    // },
    // 批量删除
    batchDel () {
      if (this.multipleSelection.length > 0) {
        this.$confirm('此操作将永久删除选中的考勤组，删除考勤组后，该考勤组下的人员考勤记录也会一并删除，您确定要删除该考勤组吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          var obj = {}
          var arr = []
          for (var i = 0; i < this.multipleSelection.length; i++) {
            arr.push(this.multipleSelection[i]._id)
            if (arr.length === this.multipleSelection.length) {
              obj._ids = arr
              this.fnDelAttendanceGroup(obj)
            }
          }
        }).catch(() => {
          this.$msg.info('已取消删除')
        })
      } else {
        this.$msg.warning('请选择要删除的考勤组！')
      }
    },
    async fnDelAttendanceGroup (obj) {
      const res = await delAttendanceGroup(obj)
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
      }
      this.fnGetAttendanceGroupList()
    },
    // 新增考勤组弹框
    openAddPage () {
      // this.$router.push('/add-checking-in-rule')
      this.dialogTitle = '新增考勤组'
      this.addForm.attendance_personnel_count = ''
      this.person_count = 0
      this.attendanceJgData = []
      this.fnAxiosAll([], false)
      this.fnGetOrganizationSeleteList()
      this.dialogVisible = true
    },
    // 编辑考勤组弹框
    async openEditPage (row) {
      this.dialogTitle = '编辑考勤组'
      this.person_count = 0
      await this.fnAxiosAll(row.attendance_personnel, true)
      this.attendanceJgData = []
      this.addForm.selectionIds = []
      console.log('row', row)
      // if (this.attendancePersonList.length > 0) { this.getTreeData(this.attendancePersonList) }
      this.row = row
      var that = this
      this.$nextTick(function () {
        that.addForm._id = row._id
        that.addForm.attendance_group_name = row.attendance_group_name
        that.addForm.attendance_rules_id = row.attendance_rules_id
        that.addForm.attendance_rules_name = row.attendance_rules_name
        that.addForm.regional_rules_id = row.regional_rules_id !== '000000000000000000000000' ? row.regional_rules_id : ''
        that.addForm.regional_rules_name = row.regional_rules_name
        that.addForm.attendance_personnel = row.attendance_personnel
        that.addForm.selected_organize_ids = row.selected_organize_ids
        that.addForm.organize_id = row.organize_id
        that.updateFormString = JSON.stringify(that.addForm)
        // that.addForm.selectionIds = that.getCheckNode(that.attendancePersonList) // 回显已选择id
        // console.log('attendancePersonList', that.attendancePersonList)
      })

      this.fnGetOrganizationSeleteList()
      this.dialogVisible = true
    },
    // 获取组织下拉列表
    async fnGetOrganizationSeleteList () {
      const params = {}
      params.select_type = 1 // 1：考勤组 2：围栏策略
      if (this.dialogTitle === '编辑考勤组') {
        console.log('have')
        params.attendance_group_id = this.row._id
      }
      const res = await getOrganizList(params)
      if (res && res.Code === 200) {
        this.organizationSeleteList = res.Data
        if (this.dialogTitle === '新增考勤组') {
          // 新增时默认选中第一项
          this.addForm.organize_id = this.organizationSeleteList[0]._id
        }
        const obj = {}
        if (this.addForm._id) {
          console.log('有id')
          obj.attendance_group_id = this.addForm._id
        }
        obj.organize_id = this.addForm.organize_id
        this.fnGetMembersWithoutConfiguredRules(obj)
      } else {
        this.organizationSeleteList = []
      }
    },
    // 递归方法
    getTreeData (data) {
      // 循环遍历json数据
      for (var i = 0; i < data.length; i++) {
        if (data[i].children.length > 0) {
          // children若不为空数组，则继续 递归调用 本方法
          this.getTreeData(data[i].children)
        } else {
          if (data[i].isshow) {
            if (data[i].persondata.length > 0) {
              this.person_count += data[i].persondata.length
              this.addForm.selectionIds.push(data[i].id)
            }
          }
        }
      }
      return data
    },
    // 递归方法
    getCheckNode (data) {
      // 循环遍历json数据
      for (var i = 0; i < data.length; i++) {
        if (data[i].children.length > 0) {
          // children若不为空数组，则继续 递归调用 本方法
          if (data[i].persondata.length > 0 && data[i].isshow) {
            this.person_count += data[i].persondata.length
            console.log('father', this.person_count)
          }
          this.getCheckNode(data[i].children)
        } else {
          if (data[i].isshow) {
            this.addForm.selectionIds.push(data[i].id)
            if (data[i].persondata.length > 0) {
              this.person_count += data[i].persondata.length
              console.log('this.person_count', this.person_count)
            }
          }
        }
      }
      return data
    },
    fnAxiosAll (id, flag) {
      var that = this
      axios.all([this.fnGetAttendanceRulesList(), this.fnGetRegionalRulesList()])
        .then(
          axios.spread(
            function (attendanceRules, regionalRules) {
              console.log('获取考勤规则下拉列---', attendanceRules)
              console.log('获取区域规则下拉列---', regionalRules)
              that.attendanceRulesList = attendanceRules && attendanceRules.Code === 200 ? attendanceRules.Data.DataList : []
              that.regionalRulesList = regionalRules && regionalRules.Code === 200 ? regionalRules.Data.DataList : []
            }
          )
        )
    },
    // 下拉选择组织后获取考勤人员下拉
    selectOrganize () {
      console.log('this.addForm.organize_id', this.addForm.organize_id)
      // 重置选择人数
      this.addForm.attendance_personnel_count = ''
      this.person_count = 0
      const obj = {}
      if (this.addForm._id) {
        obj.attendance_group_id = this.addForm._id
      }
      obj.organize_id = this.addForm.organize_id
      this.fnGetMembersWithoutConfiguredRules(obj)
    },
    // 获取该组织下未配置的考勤人员+收费人员
    async fnGetMembersWithoutConfiguredRules (params) {
      const res = await getMembersWithoutConfiguredRules(params)
      this.attendancePersonList = res.Code === 200 && res.Data ? res.Data : []
      console.log('\\this.attendancePersonList', this.attendancePersonList)
      this.getCheckNode(this.attendancePersonList) // 回显已选择id
      this.addForm.attendance_personnel_count = '已选择' + this.person_count + '人'
    },
    // 获取考勤规则下拉列
    fnGetAttendanceRulesList () {
      return getAttendanceRulesList({
        PageIndex: 0,
        PageSize: 0
      })
    },
    // 获取区域规则下拉列
    fnGetRegionalRulesList () {
      return getRegionalRulesList({
        PageIndex: 0,
        PageSize: 0
      })
    },
    // 考勤规则下拉框发生改变时触发
    attendanceRulesChange (id) {
      var arr = this.attendanceRulesList.filter((item) => {
        return item._id === id
      })
      this.addForm.attendance_rules_name = arr[0].rule_name
    },
    // 区域规则下拉框发生改变时触发
    regionalRulesChange (id) {
      var arr = this.regionalRulesList.filter((item) => {
        return item._id === id
      })
      this.addForm.regional_rules_name = arr.length > 0 ? arr[0].rule_name : ''
    },
    // handleCheckChangeArea(data, checked, indeterminate) {
    //   console.log(data, checked, indeterminate);
    //   var nodes = this.$refs.tree.getCheckedNodes();
    //   console.log(nodes);
    // },
    // 保存
    submitAddForm () {
      // 对整个表单进行校验
      this.$refs.addFormRef.validate((valid) => {
        // 成功
        if (valid) {
          if (this.updateFormString === JSON.stringify(this.addForm)) {
            this.dialogVisible = false
            return false
          }
          var obj = {}
          obj = {
            attendance_group_name: this.addForm.attendance_group_name,
            attendance_rules_id: this.addForm.attendance_rules_id,
            attendance_rules_name: this.addForm.attendance_rules_name,
            regional_rules_id: this.addForm.regional_rules_id ? this.addForm.regional_rules_id : '000000000000000000000000',
            regional_rules_name: this.addForm.regional_rules_name ? this.addForm.regional_rules_name : null,
            organize_id: this.addForm.organize_id ? this.addForm.organize_id : null,
            attendance_personnel: this.addForm.attendance_personnel ? this.addForm.attendance_personnel : [],
            selected_organize_ids: this.addForm.selected_organize_ids ? this.addForm.selected_organize_ids : []
          }
          if (this.addForm._id) {
            // 编辑考勤组前，友情提示用户
            this.fnFriendshipToSubmit()
            // this.fnUpdateAttendanceGroup();
          } else {
            this.fnAddAttendanceGroup(obj)
          }
        } else {
          return false
        }
      })
    },
    // 新增考勤组
    async fnAddAttendanceGroup (obj) {
      const res = await addAttendanceGroup(obj)
      console.log('新增考勤组---', res)
      if (res && res.Code === 200) {
        this.dialogVisible = false
        this.onSubmit()
        // this.fnGetAttendanceGroupList();
      }
    },
    // 编辑考勤组前，友情提示用户
    fnFriendshipToSubmit () {
      // console.log(this.addForm.attendance_personnel.sort().toString());
      // console.log(this.row.attendance_personnel.sort().toString());
      if (this.addForm.attendance_rules_id !== this.row.attendance_rules_id || this.addForm.attendance_personnel.sort().toString() !== this.row.attendance_personnel.sort().toString()) {
        console.log('提示')
        this.isFriendshipToSubmit = true
      } else {
        this.fnUpdateAttendanceGroup()
      }
    },
    // 编辑考勤组
    async fnUpdateAttendanceGroup () {
      this.isFriendshipToSubmit = false
      const res = await updateAttendanceGroup({
        _id: this.addForm._id,
        attendance_group_name: this.addForm.attendance_group_name,
        attendance_rules_id: this.addForm.attendance_rules_id,
        attendance_rules_name: this.addForm.attendance_rules_name,
        regional_rules_id: this.addForm.regional_rules_id ? this.addForm.regional_rules_id : '000000000000000000000000',
        regional_rules_name: this.addForm.regional_rules_name ? this.addForm.regional_rules_name : null,
        organize_id: this.addForm.organize_id ? this.addForm.organize_id : null,
        attendance_personnel: this.addForm.attendance_personnel ? this.addForm.attendance_personnel : [],
        selected_organize_ids: this.addForm.selected_organize_ids ? this.addForm.selected_organize_ids : []
      })
      console.log('编辑考勤组---', res)
      if (res && res.Code === 200) {
        this.dialogVisible = false
        this.fnGetAttendanceGroupList()
      }
    },
    // 对话框关闭时触发
    dialogClose (addFormRef) {
      this.dialogVisible = false
      this.$refs[addFormRef].resetFields()
      this.addForm._id = ''
      this.addForm.regional_rules_id = ''
      this.addForm.attendance_rules_name = ''
      this.addForm.regional_rules_name = ''
    },
    openAttendancePersonnel (row) {
      this.fnGetAttendanceStatisticsInspectorList(row._id)
    },
    // 获取考勤组下的人员
    async fnGetAttendanceStatisticsInspectorList (id) {
      const res = await getAttendanceStatisticsInspectorList({
        attendance_group_id: id
      })
      console.log('获取考勤组下的人员--', res)
      if (res && res.Code === 200) {
        this.attendancePersonnelData = res.Data
        this.isAttendancePersonnel = true
      } else {
        this.attendancePersonnelData = []
      }
    }
  }
}
</script>

<style scoped lang="less">.setstyle {
  min-height: 200px;
  padding: 10px 0 !important;
  margin: 0;
  overflow: auto;
  cursor: default !important;

  .setstyle.hover,
  .setstyle:hover {
    background-color: #fff;
  }
}</style>
